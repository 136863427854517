import React, { FC, useEffect, useId } from "react";
import Heading from "components/Heading/Heading";
import Glide from "@glidejs/glide";
import CardCategory2 from "components/CardCategories/CardCategory2";
import CardCategory3 from "components/CardCategories/CardCategory2Solu";
import department1Png from "images/collections/department1.png";
import department2Png from "images/collections/department2.png";
import department3Png from "images/collections/department3.png";
import department4Png from "images/collections/department4.png";
import { Link } from "react-router-dom";
import { Categories, getAllProducts, getProductById3 } from "Services/API/Get";
import { IMAGE_SERVER_URL_DEV } from "Constant/System";
import category1 from "images/categories/1.png";
import category2 from "images/categories/2.png";
import category3 from "images/categories/3.png";
import category4 from "images/categories/4.png";
import category5 from "images/categories/5.png";
import category6 from "images/categories/6.png";
import category7 from "images/categories/7.png";
import category8 from "images/categories/8.png";
import category9 from "images/categories/9.png";
import category10 from "images/categories/10.png";
import category11 from "images/categories/11.png";
import category12 from "images/categories/12.png";
import category13 from "images/categories/13.png";
import category14 from "images/categories/14.png";
import category15 from "images/categories/15.png";
import category16 from "images/categories/16.png";
import category17 from "images/categories/17.png";
import category18 from "images/categories/18.png";

import category19 from "images/categories/Mops.jpg";
import category20 from "images/categories/Vacuum.jpg";
import category21 from "images/categories/Machinery.jpg";
import category22 from "images/categories/Washroom.jpg";
import category23 from "images/categories/Chemicals.png";
import category24 from "images/categories/WindowCleaning.jpg";
import category25 from "images/categories/Wipes.jpg";
import category26 from "images/categories/DustControl.jpg";
import category27 from "images/categories/Carts.jpg";
import category28 from "images/categories/Brushware.png";
import category29 from "images/categories/Brooms.jpg";
import category30 from "images/categories/Buckets.jpg";
import category31 from "images/categories/Dispensers.jpg";


export interface CardCategoryData {
  name: string;
  desc: string;
  img: string;
  color?: string;
}
const CATS: CardCategoryData[] = [
  {
    name: "Travel Kits",
    desc: "20+ categories",
    img: department1Png,
    color: "bg-indigo-100",
  },
  {
    name: "Beauty Products",
    desc: "10+ categories",
    img: department2Png,
    color: "bg-slate-100",
  },
  {
    name: "Sport Kits",
    desc: "34+ categories",
    img: department3Png,
    color: "bg-sky-100",
  },
  {
    name: "Pets Food",
    desc: "12+ categories",
    img: department4Png,
    color: "bg-orange-100",
  },
];
export interface SectionSliderCategoriesProps {
  className?: string;
  itemClassName?: string;
  heading?: string;
  subHeading?: string;
  data?: CardCategoryData[];
  data2?: any;
  data3?: any;
  cols?: any;
  type?: any
}

const SectionSliderCategories: FC<SectionSliderCategoriesProps> = ({
  heading = "CLEANING PRODUCTS",
  subHeading = "",
  className = "",
  itemClassName = "",
  data = CATS,
  data2,
  data3,
  cols = 4,
  type = "solu"
}) => {
  const id = useId();
  const UNIQUE_CLASS = "glidejs" + id.replace(/:/g, "_");

  const [categories, setcategories] = React.useState<any>([]);
  const [images, setimages] = React.useState<any>([]);

  React.useEffect(() => {

    getallCategory();

  }, []);

  const getallCategory = async () => {

    Categories()
      .then(async (response) => {
        console.log("category>>>>", response?.data);
        setcategories(response.data);

        var newArr: any = []

        const updateMySubs = await response.data.map(async (item: any, index: any) => {

          if (`${item?.maincategory}`.trim() !== "Industry Solutions" && item?.maincategory) {

            let img = "";
            switch (`${item?.maincategory}`.trim()) {
              case "Mops & Polish Spreaders":
                img = category19;
                break;
              case "Vacuum Cleaners & Parts":
                img = category20;
                break;
              case "Machinery":
                img = category21;
                break;
              case "Garbage Bags & Bins":
                img = category4;
                break;
              case "Washroom & Paper Products":
                img = category22;
                break;
              case "Chemicals":
                img = category23;
                break;
              case "Window Cleaning":
                img = category24;
                break;
              case "Safety":
                img = category8;
                break;
              case "Matting":
                img = category9;
                break;
              case "Wipes, Cloths & More":
                img = category25;
                break;
              case "Victory Innovations":
                img = category10;
                break;
              case "Dust Control":
                img = category26;
                break;
              case "Hard Floor Care & Pads":
                img = category13;
                break;
              case "Carts & Trolleys":
                img = category27;
                break;
              case "Brushware & Dustpans":
                img = category28;
                break;
              case "Brooms & Floor Squeegees":
                img = category29;
                break;
              case "Buckets":
                img = category30;
                break;
              case "Dispensers":
                img = category31;
                break;
              case "Covid Clean":
                img = category18;
                break;
              // case :
              //   img = category1;
              //   break;
              // case :
              //   img = category1;
              //   break;

            }

            item.img = img

            newArr.push(item)

          }





          // await getProductById3(item.mainId)
          //   .then((res) => {

          //     newArr.push(res.data[0])
          //   })
          //   .catch((error) => {
          //     console.error(">errorrrrr>", error);

          //   });



        });

        console.log("img Arr>>>>", newArr)
        setimages(newArr)


      })
      .catch(() => {

      });

  }

  // useEffect(() => {
  //   const OPTIONS: Glide.Options = {
  //     perView: 4,
  //     gap: 32,
  //     bound: true,
  //     breakpoints: {
  //       1280: {
  //         perView: 4,
  //       },
  //       1024: {
  //         gap: 20,
  //         perView: 3.4,
  //       },
  //       768: {
  //         gap: 20,
  //         perView: 3,
  //       },
  //       640: {
  //         gap: 20,
  //         perView: 2.3,
  //       },
  //       500: {
  //         gap: 20,
  //         perView: 1.4,
  //       },
  //     },
  //   };

  //   let slider = new Glide(`.${UNIQUE_CLASS}`, OPTIONS);
  //   slider.mount();
  //   // @ts-ignore
  //   return () => slider.destroy();
  // }, [UNIQUE_CLASS]);

  return (
    <div className={`nc-SectionSliderCategories ${className}`}>
      <div
      // className={`${UNIQUE_CLASS} flow-root`}
      >
        <Heading desc={subHeading} isCenter={true}
        // hasNextPrev
        >
          {heading}
        </Heading>
        <div
        // className="glide__track" 
        // data-glide-el="track"
        >
          <ul
            className={`grid sm:grid grid-cols-2 lg:grid-cols-${cols} gap-4 `}
          // className="glide__slides"
          >


            {
              type !== "solu" ?
                <>
                  {images.length > 0 && images?.map((item: any, index: any) => (
                    <li key={index}
                    // className={`glide__slide ${itemClassName}`}
                    >
                      <CardCategory2
                        // featuredImage={data2[index].img}
                        featuredImage={
                          // images.length >= 16 && images[index] != undefined ?
                          //   `${IMAGE_SERVER_URL_DEV}${JSON.parse(images[index]?.images)?.filter((item: { thumbnail: boolean; }) => item?.thumbnail === true)[0]?.path}`
                          //   :
                          //   ""
                          item?.img
                        }
                        name={item?.maincategory}
                        item={item}
                        // desc={item.desc}
                        bgClass={item.color}
                        type="cat"
                      />
                    </li>
                  ))}


                </>
                :
                <>
                  {data3?.map((item: any, index: any) => (
                    <li key={index}
                    // className={`glide__slide ${itemClassName}`}
                    >
                      <CardCategory3
                        featuredImage={item.image}
                        name={item.name}
                        item={item}
                        // desc={item.desc}
                        bgClass={data2[index]?.color}
                        type="sub"
                      />
                    </li>
                  ))}


                </>
            }

            {/* <li className={`glide__slide ${itemClassName}`}>
              <div
                className={`flex-1 relative w-full h-0 rounded-2xl overflow-hidden group aspect-w-1 aspect-h-1 bg-slate-100`}
              >
                <div>
                  <div className="absolute inset-y-6 inset-x-10 flex flex-col sm:items-center justify-center">
                    <div className="flex relative text-slate-900">
                      <span className="text-base font-semibold ">
                        More collections
                      </span>
                      <svg
                        className="absolute left-full w-5 h-5 ml-2 rotate-45 group-hover:scale-110 transition-transform"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M18.0701 9.57L12.0001 3.5L5.93005 9.57"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                        <path
                          d="M12 20.4999V3.66992"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                    </div>
                    <span className="text-xs mt-1 text-slate-800">
                      Show me more
                    </span>
                  </div>
                </div>
                <Link
                  to={"/"}
                  className="opacity-0 group-hover:opacity-100 absolute inset-0 bg-black bg-opacity-10 transition-opacity"
                ></Link>
              </div>
            </li> */}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SectionSliderCategories;
